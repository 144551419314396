import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faScaleUnbalanced,
  faCheck,
  faTrophy,
  faMedal,
  faAward,
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  faCalendarDays,
  faQuoteRight,
  faQuoteLeft,
  faArrowUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import BackToTopButton from '../../components/BackToTopButton'
import Link from '../../components/LinkWrapper'
import Modal from '../../components/Modal'
import i18n from '../../locales/he.yaml'

const ExhibitionQuery = graphql`
  query ExhibitionQuery {
    declaration: file(
      relativePath: { eq: "מגילת-העצמאות-מפוסלת-בבצק-סוכר.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    bengurion: file(
      relativePath: { eq: "בן-גוריון-עומד-על-הראש-מפוסל-בבצק-סוכר.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    survival: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-הישרדות-ענקית-מרחפת-בגובה-מטר-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
  }
`

const ExhibitionTemplate = ({ pathname, search, data }) => {
  const [registrationState, setRegistrationState] = useState(null)
  const [iframeUrl, setIframeUrl] = useState(null)
  useEffect(() => {
    setIframeUrl(
      'https://www.karinnarts.co.il/forms/?ff_landing=19&embedded=1&' +
        search
          .slice(1)
          .split('&')
          .filter(
            (item) =>
              item.split('=')[0] !== 'ns_url' && item.split('=')[0] !== 'mid'
          )
          .join('&')
    )
  }, [])
  const { declaration, bengurion, survival } = data

  const BULLETS_IMAGE = [declaration, bengurion]
  const HOW_IT_WORKS_ICONS = [fa1, fa2, fa3, fa4, fa5]

  return (
    <>
      <BackToTopButton selectorId='exhibition' />
      {!registrationState ? null : (
        <Modal isOpen onClose={() => setRegistrationState(null)}>
          <div
            className={clsx('box', {
              'is-hidden': registrationState !== 'competition'
            })}
          >
            <div className='content has-text-centered'>
              <h3>{i18n.exhibition.registration.competition.title}</h3>
              <p>{i18n.exhibition.registration.competition.description}</p>
              <p className='is-italic'>
                {i18n.exhibition.registration.competition.hint}
              </p>
              <p className='has-text-centered'>
                <a
                  className='button is-text'
                  href='https://s3.eu-central-1.amazonaws.com/public.karinnarts.co.il/תחרות+אינטרנטית+לעיצוב+עוגות+לכבוד+יום+העצמאות+ה-75+של+מדינת+ישראל+-+תקנון.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  {i18n.exhibition.registration.competition.terms}
                </a>
              </p>
              <p className='has-text-centered'>
                <a
                  className='button is-nude'
                  href='https://secure.cardcom.solutions/e/H1Ec3H0gl0egXnma6OwA'
                  // href='https://mrng.to/QrKQhfgrUy'
                  target='_blank'
                  rel='noreferrer'
                >
                  {i18n.exhibition.registration.competition.callToAction}
                </a>
              </p>
            </div>
          </div>
          {iframeUrl ? (
            <div
              className={clsx('box', {
                'is-hidden': registrationState !== 'exhibition'
              })}
            >
              <div className='content has-text-centered'>
                <h3>{i18n.exhibition.registration.exhibition.title}</h3>
                <p>{i18n.exhibition.registration.exhibition.description}</p>
                <div className='columns is-centered'>
                  <div className='column is-full-mobile is-10-tablet is-6-desktop'>
                    <iframe
                      id='fluentform'
                      width='100%'
                      height='100%'
                      loading='lazy'
                      style={{ minHeight: '400px', width: '100%' }}
                      frameBorder='0'
                      onLoad={(event) => {
                        event.target.style.height =
                          event.target.contentWindow.document.body
                            .scrollHeight + 'px'
                      }}
                      src={iframeUrl}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </Modal>
      )}
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/academy/'}>{i18n.header.academy}</Link>
                </li>
                <li className='is-active'>
                  <Link to={pathname} aria-current='page'>
                    {i18n.header.exhibition}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column'>
                <p className='is-size-4 has-text-weight-bold has-text-nude'>
                  {i18n.exhibition.tagline}
                </p>
                <h1 className='is-size-3'>{i18n.exhibition.title}</h1>
                <h2 className='is-size-4'>{i18n.exhibition.subtitle}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {i18n.exhibition.bullets.map((bullet, index) => (
        <div
          id={index === 1 ? 'exhibition' : undefined}
          key={index}
          className={clsx('hero', {})}
        >
          <div className='hero-body'>
            <div className='container'>
              <div
                className={clsx(
                  'columns is-mobile is-multiline is-centered is-vcentered',
                  {
                    'direction-row-reverse': index % 2 === 1
                  }
                )}
              >
                <div
                  className={clsx(
                    'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen',
                    {
                      'is-offset-1-widescreen': index % 2 === 1
                    }
                  )}
                >
                  <GatsbyImage
                    image={getImage(BULLETS_IMAGE[index])}
                    title={bullet.image.title}
                    alt={bullet.image.alt}
                    className='live-image'
                    style={{
                      maxWidth: getImage(BULLETS_IMAGE[index]).width,
                      margin: '0 auto' // used to center the image
                    }}
                    loading='eager'
                  />
                  <div className='is-flex justify-center content'>
                    <p className='has-text-centered has-text-gold-black'>
                      {bullet.image.title}
                    </p>
                  </div>
                </div>
                <div
                  className={clsx(
                    'column is-full-mobile is-10-tablet is-6-desktop',
                    {
                      'is-offset-1-widescreen': index % 2 === 0
                    }
                  )}
                >
                  <div className='content'>
                    <h3>{bullet.title}</h3>
                    <p>{bullet.description}</p>
                    <strong>{bullet.question}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <FontAwesomeIcon
              icon={faScaleUnbalanced}
              className='has-text-nude is-margin-bottom-4'
              size='2x'
            />
            <div className='is-margin-bottom-4'>
              <h3>{i18n.exhibition.difference.title}</h3>
            </div>
            <p>{i18n.exhibition.difference.description}</p>
            <p className='is-italic'>{i18n.exhibition.difference.hint}</p>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='container content has-text-centered'>
              <FontAwesomeIcon
                icon={faAward}
                className='has-text-nude is-margin-bottom-4'
                size='2x'
              />
              <div className='is-margin-bottom-6'>
                <h3 className='mb-0'>{i18n.exhibition.communityPrize.title}</h3>
                <a
                  className='button has-text-nude is-text'
                  href='/academy/'
                  target='_blank'
                >
                  {i18n.exhibition.communityPrize.subtitle}
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className='has-text-nude ml-2 mt-1'
                    size='xs'
                  />
                </a>
              </div>
            </div>
            <div className='columns is-multiline is-mobile is-centered'>
              <div className='column is-12-mobile is-8-tablet is-6-desktop'>
                <p>{i18n.exhibition.communityPrize.description}</p>
                <p className='is-italic has-text-centered has-text-weight-bold'>
                  {i18n.exhibition.communityPrize.hint}
                </p>
              </div>
              <div className='column is-5-desktop is-offset-1-desktop is-8-tablet is-12-mobile'>
                <p className='has-text-centered has-text-weight-bold'>
                  {i18n.exhibition.communityPrize.testimony.title}
                </p>
                <FontAwesomeIcon icon={faQuoteRight} />
                <p className='has-text-centered has-text-gold-black is-margin-bottom-0'>
                  {i18n.exhibition.communityPrize.testimony.text}
                </p>
                <div className='has-text-right'>
                  <FontAwesomeIcon icon={faQuoteLeft} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='has-text-centered is-margin-bottom-6'>
              <FontAwesomeIcon
                icon={faTrophy}
                className='has-text-nude is-margin-bottom-4'
                size='2x'
              />
              <div>
                <h3 className='mb-0'>{i18n.exhibition.prizes.title}</h3>
                <a
                  className='button has-text-nude is-text'
                  href='/academy/'
                  target='_blank'
                >
                  {i18n.exhibition.prizes.subtitle}
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className='has-text-nude ml-2 mt-1'
                    size='xs'
                  />
                </a>
              </div>
            </div>
            <div className='columns is-multiline is-mobile'>
              {[
                i18n.exhibition.prizes.first,
                i18n.exhibition.prizes.second,
                i18n.exhibition.prizes.third
              ].map((prize, index) => (
                <div
                  key={index}
                  className='column is-one-third-desktop is-half-tablet is-full-mobile'
                >
                  <div
                    className='box lift is-flex is-flex-direction-column'
                    style={{ height: '100%' }}
                  >
                    <div className='has-text-centered'>
                      <FontAwesomeIcon
                        icon={faMedal}
                        className='has-text-nude'
                      />
                      <h4 className='is-margin-top-4'>{prize.title}</h4>
                    </div>
                    {prize.bullets.map((bullet, i) => (
                      <div
                        key={bullet.title}
                        className={clsx('is-relative', {
                          'mt-4': i > 0,
                          'mb-4': prize.bullets.length - 1 === i
                        })}
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          className='has-text-nude is-size-7 is-absolute'
                          style={{ top: 8 }}
                        />
                        <div className='is-margin-left-5'>
                          <p>{bullet}</p>
                        </div>
                      </div>
                    ))}
                    <p className='has-text-centered is-italic has-text-weight-bold mt-auto'>
                      {prize.total}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body pt-0'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column is-6-widescreen is-8-desktop is-10-tablet is-12-mobile'>
                <div className='columns is-flex-direction-column is-mobile is-align-items-center'>
                  <div className='columns is-justify-content-center'>
                    <div className='column is-narrow'>
                      <p className='has-text-centered has-text-weight-bold'>
                        {i18n.exhibition.prizes.testimony.title}
                      </p>
                    </div>
                  </div>
                  <div className='column is-align-self-flex-start'>
                    <FontAwesomeIcon icon={faQuoteRight} />
                  </div>
                  <div className='column is-padding-top-0 is-padding-bottom-0'>
                    <p className='has-text-gold-black is-margin-bottom-0'>
                      {i18n.exhibition.prizes.testimony.text}
                    </p>
                  </div>
                  <div className='column is-align-self-flex-end'>
                    <FontAwesomeIcon icon={faQuoteLeft} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <FontAwesomeIcon
              icon={faCalendarDays}
              className='has-text-nude is-margin-bottom-4'
              size='lg'
            />
            <div className='is-margin-bottom-7'>
              <h3>{i18n.exhibition.howItWorks.title}</h3>
            </div>
            <div className='columns is-multiline'>
              {i18n.exhibition.howItWorks.steps.map((step, index) => (
                <div key={index} className='column is-10 is-offset-1'>
                  <FontAwesomeIcon
                    icon={HOW_IT_WORKS_ICONS[index]}
                    className='has-text-nude'
                  />
                  <h4 className='is-margin-top-4'>{step.title}</h4>
                  {step.hint ? <p className='is-italic'>{step.hint}</p> : null}
                  <p>{step.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div id='registration' className='hero'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <h3>{i18n.exhibition.registration.title}</h3>
            <div className='buttons are-large is-centered'>
              <button
                className='button is-nude'
                onClick={() => setRegistrationState('competition')}
              >
                {i18n.exhibition.registration.competition.cta}
              </button>
              <button
                className='button is-nude'
                onClick={() => setRegistrationState('exhibition')}
              >
                {i18n.exhibition.registration.exhibition.cta}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-mobile is-multiline is-centered'>
              <div className='column content is-margin-bottom-0 is-full-mobile is-10-tablet is-6-desktop'>
                <h3>{i18n.exhibition.about.title}</h3>
                <p>{i18n.exhibition.about.description}</p>
              </div>
              <div className='column is-4-fullhd is-5-widescreen is-half-tablet is-10-mobile is-offset-2-fullhd is-offset-1-widescreen is-order-3-touch'>
                <GatsbyImage
                  image={getImage(survival)}
                  title={i18n.exhibition.about.mainImage.title}
                  alt={i18n.exhibition.about.mainImage.alt}
                  className='live-image'
                  style={{
                    maxWidth: getImage(survival).width,
                    margin: '0 auto' // used to center the image
                  }}
                />
                <div className='is-flex justify-center content'>
                  <p className='has-text-centered has-text-gold-black'>
                    {i18n.exhibition.about.mainImage.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Exhibition = ({ location: { pathname, search } }) => {
  const data = useStaticQuery(ExhibitionQuery)
  return (
    <Layout>
      <Seo
        metadata={i18n.exhibition.metadata}
        pathname={pathname}
        robots='noindex, nofollow'
        og={{
          image: getSrc(data.declaration),
          alt: i18n.exhibition.mainImage.alt
        }}
        schemaMarkup={{
          breadcrumbs: [
            { name: i18n.header.academy, path: '/academy/' },
            { name: i18n.header.exhibition, path: '/academy/exhibition/' }
          ]
        }}
      />
      <ExhibitionTemplate pathname={pathname} search={search} data={data} />
    </Layout>
  )
}

export default Exhibition
